<template lang="">
    <b-row class="ml-0 mb-3">
        <b-col cols="1" class="p-0">
            배터리용량(kWh)
        </b-col>
        <b-col cols="5" class="p-0">
            {{commaRender(battery.battery_capacity)}}
        </b-col>
        <b-col cols="1" class="p-0">
            배터리종류
        </b-col>
        <b-col cols="5" class="p-0">
            {{commaRender(battery.battery_type)}}
        </b-col>
        <b-col cols="1" class="p-0">
            항속거리(km)
        </b-col>
        <b-col cols="5" class="p-0">
            {{commaRender(battery.cruising_distance)}}
        </b-col>
        <b-col cols="1" class="p-0">
            복합연비(km/kWh)
        </b-col>
        <b-col cols="5" class="p-0">
            {{commaRender(battery.combined_fuel_economy_electric)}}
        </b-col>
        <b-col cols="1" class="p-0">
            도심연비(km/kWh)
        </b-col>
        <b-col cols="5" class="p-0">
            {{commaRender(battery.city_fuel_economy_electricity)}}
        </b-col>
        <b-col cols="1" class="p-0">
            고속연비(km/kWh)
        </b-col>
        <b-col cols="5" class="p-0">
            {{commaRender(battery.high_speed_fuel_economy_electric)}}
        </b-col>
    </b-row> 
</template>
<script>
import { createHelpers } from 'vuex-map-fields';
const { mapFields } = createHelpers({
    getterType: 'main/getField', 
    mutationType: 'main/updateField', 
});
export default {
    name : 'BatteryResult',
    computed : {
        ...mapFields([
            'result.battery'
        ]),
    },
    methods: {
        commaRender(value) {
            return this.$utils.commaRender(value);
        },
    },
}
</script>
<style lang="">
    
</style>