<template lang="">
    <b-modal
        size="lg"
        :ok-only=true
        id="modal-center2"
        scrollable
        title="상세그래프"
        ok-title="닫기">
        <b-overlay
            id="overlay-background3"
            :show="chartDataLoading"
            variant="white"
            opacity="0.8"
            rounded="sm"
        >
            <vue-apex-charts
                ref="chart1"
                type="line"
                height="400"
                :options="chart.options"
                :series="chart.series"
            />
            <vue-apex-charts ref="chart2" type="area" height="130" :options="chart.optionsLine" :series="chart.series" />
            <b-row  class="d-flex justify-content-center">
                 <b-button-group>
                    <b-button variant="outline-primary" 
                    v-for="(item,index) in hhList"
                    @click="loadChart(item)">
                        {{item.text}}
                    </b-button>
                </b-button-group>
            </b-row>
        </b-overlay>
    </b-modal>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

import { createHelpers } from 'vuex-map-fields';
const { mapFields } = createHelpers({
    getterType: 'main/getField', 
    mutationType: 'main/updateField', 
});

export default {
    name : 'ChartModal',
    components : {
        VueApexCharts
    },
    computed : {
        ...mapFields([
            'chartDataLoading',
            'sub.battery.find.modal.chart',
            'sub.battery.find.modal.chart.hh',
            'sub.battery.find.modal.chart.hhList'
        ]),
    },
    methods : {
        loadChart(item){
            let me = this;
            me.$store.dispatch('main/findBatteryInfoChart',{
                me : me,
                item : item
            })
        }
    }
}
</script>
<style lang="">
    
</style>