<template lang="">
    <b-row class="ml-0">
        <b-col cols="1" class="p-0">
            차대번호
        </b-col>
        <b-col cols="5" class="p-0">
            {{car.vin}}
        </b-col>
        <b-col cols="1" class="p-0">
            출고가격
        </b-col>
        <b-col cols="5" class="p-0">
            {{commaRender(car.firstPrice)}}
        </b-col>
        <b-col cols="1" class="p-0">
            연식
        </b-col>
        <b-col cols="5" class="p-0">
            {{car.yyyy}}
        </b-col>
        <b-col cols="1" class="p-0">
            배기량
        </b-col>
        <b-col cols="5" class="p-0">
            {{commaRender(car.cc)}}
        </b-col>
        <b-col cols="1" class="p-0">
            제조사
        </b-col>
        <b-col cols="5" class="p-0">
            {{car.brandName}}
        </b-col>
        <b-col cols="1" class="p-0">
            연료
        </b-col>
        <b-col cols="5" class="p-0">
            {{car.fuel}}
        </b-col>
        <b-col cols="1" class="p-0">
            차량명
        </b-col>
        <b-col cols="5" class="p-0">
            {{car.carName}}
        </b-col>
        <b-col cols="1" class="p-0">
            미션
        </b-col>
        <b-col cols="5" class="p-0">
            {{car.misstion}}
        </b-col>
        <b-col cols="1" class="p-0">
            차량세부명칭
        </b-col>
        <b-col cols="6" class="p-0">
            {{car.carNameDetail}}
        </b-col>
    </b-row>  
</template>
<script>
import { createHelpers } from 'vuex-map-fields';
const { mapFields } = createHelpers({
    getterType: 'main/getField', 
    mutationType: 'main/updateField', 
});
export default {
    name : 'CarResult',
    computed : {
        ...mapFields([
            'result.car'
        ]),
    },
    methods: {
        commaRender(value) {
            return this.$utils.commaRender(value);
        },
    }
}
</script>
<style lang="">
    
</style>