<template lang="">
    <b-container fluid class="pb-2">
        <TopBar />
        <!-- 시작 -->
        <div class="mt-2">
            <b-card>
                <b-overlay
                    id="overlay-background"
                    :show="loading"
                    variant="white"
                    opacity="0.8"
                    rounded="sm"
                >
                    <!-- 검색 툴바 -->
                    <Search />
                    <!-- 차량정보 검색 결과 -->
                    <h4>차량정보</h4>
                    <hr/>
                    <CarResult />

                    <!-- 배터리정보 검색 결과 -->
                    <h4 class="mt-2">배터리정보</h4>
                    <hr/>
                    
                    <BatteryResult />

                    <!-- 서브 상세보기 버튼 그룹-->
                    <b-row  class="d-flex justify-content-center">
                        <b-button
                            v-for="(item,index) in buttonInfo"
                            class="mr-1"
                            variant="outline-primary"
                            :disabled="result.car.id == ''"
                            @click="subInfo(index)">
                            <span class="text-nowrap">{{item.text}}</span>
                        </b-button>
                    </b-row>

                    <!-- 서브페이지 -->
                    <BatteryView v-if="index == 0"/>
                    <BatteryRegister v-else-if="index == 1"/>
                    <RepairView v-else-if="index == 2"/>
                    <RepairRegister v-else-if="index == 3"/>
                </b-overlay>
            </b-card>
        </div>
    </b-container>
</template>
<script>
import TopBar from './TopBar';
import Search from './search_component/Search';
import CarResult from './search_component/CarResult';
import BatteryResult from './search_component/BatteryResult';

// 서브 컴포너트
import BatteryView from './sub_components/battery/view/BatteryView';
import BatteryRegister from './sub_components/battery/register/BatteryRegister';
import RepairView from './sub_components/repair/view/RepairView';
import RepairRegister from './sub_components/repair/register/RepairRegister';


import { createHelpers } from 'vuex-map-fields';
const { mapFields } = createHelpers({
    getterType: 'main/getField', 
    mutationType: 'main/updateField', 
});

export default {
    name : 'Main',
    components : {
        // 상단 네비게이션 바
        TopBar,
        // 검색 툴바
        Search,
        // 차량정보
        CarResult,
        // 배터리 정보 
        BatteryResult,

        BatteryView,
        BatteryRegister,
        RepairView,
        RepairRegister
    },
    mounted(){
        this.$store.commit('main/resetInfo');
    },
    computed : {
        ...mapFields([
            'buttonInfo',
            'result',
            'index',
            'loading',
        ]),
    },
    methods : {
        subInfo(index){
            this.$store.dispatch('main/subResult',{
                index : index
            });
        }
    }
}
</script>
<style lang="scss">
    .custom-file-input:lang(en) ~ .custom-file-label::after {
        content: '찾아보기';
    }

    .hidden_header {
        display : none;
    }
    table.b-table td.w10{ width: 10%; } 
    table.b-table td.w20{ width: 20%; } 
    table.b-table td.w30{ width: 30%; } 
    table.b-table td.w40{ width: 40%; } 
    table.b-table td.w50{ width: 50%; } 
    table.b-table td.w60{ width: 60%; } 
    table.b-table td.w70{ width: 70%; }
    table.b-table td.w80{ width: 80%; } 
    table.b-table td.w90{ width: 90%; } 
    table.b-table td.w15{ width: 15%; } 
    table.b-table td.w25{ width: 25%; } 
    table.b-table td.w35{ width: 35%; } 
    table.b-table td.w45{ width: 45%; } 
    table.b-table td.w55{ width: 55%; } 
    table.b-table td.w65{ width: 65%; } 
    table.b-table td.w75{ width: 75%; } 
    table.b-table td.w85{ width: 85%; }
    table.b-table td.w95{ width: 95%; }

    [dir] .table th, [dir] .table td {
        padding: 0px;
        border-top-width : 0px;
    }
</style>