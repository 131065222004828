<template lang="">
    <b-modal
        size="lg"
        :ok-only=true
        id="modal-center"
        scrollable
        title="수리정비 상세내역"
        ok-title="닫기">
            <!-- 목록 -->
            <b-table responsive show-empty class="position-relative fix_table text-nowrap" primary-key="id" empty-text="존재하지 않습니다"
                    thead-class="hidden_header"
                    :items="detail.list"
                    :fields="detail.fields">
                    <template v-slot:cell(price)="{value,item,field,index}">
                                {{commaRender(value)}}
                    </template>
            </b-table>
            <hr/>
            <!-- 총합 -->
            <b-table responsive show-empty class="position-relative fix_table text-nowrap" primary-key="id" empty-text="존재하지 않습니다"
                    thead-class="hidden_header"
                    :items="detail.sum"
                    :fields="detail.fields">
                    <template v-slot:cell(price)="{value,item,field,index}">
                                {{commaRender(value)}}
                    </template>
            </b-table>
    </b-modal>
</template>
<script>
import { createHelpers } from 'vuex-map-fields';
const { mapFields } = createHelpers({
    getterType: 'main/getField', 
    mutationType: 'main/updateField', 
});

export default {
    name : 'DetailModal',
    computed : {
        ...mapFields([
            'sub.repair.find.modal.detail'
        ]),
    },
    methods: {
        commaRender(value) {
            return this.$utils.commaRender(value);
        },
    },
}
</script>
<style lang="">
    
</style>