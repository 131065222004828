<template lang="">
    <b-pagination
        v-model="find.currentPage"
        :total-rows="find.totalCount"
        :per-page="find.perPage"
        first-number last-number
        class="mb-0 mt-1 mt-sm-0"
        align="center"
        @page-click="paging">
        <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
        </template>
        <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
        </template>
    </b-pagination>
</template>
<script>
import { createHelpers } from 'vuex-map-fields';
const { mapFields } = createHelpers({
    getterType: 'main/getField', 
    mutationType: 'main/updateField', 
});

export default {
    name : 'Page',
    computed : {
        ...mapFields([
            'sub.repair.find',
            'index'
        ]),
    },
    methods: {
        paging(e,paging){
            e.preventDefault();
            this.$store.dispatch('main/subResult',{
                index : this.index,
                paging : paging
            });
        }
    },
}
</script>
<style lang="">
    
</style>