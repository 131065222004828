<template lang="">
    <div>
        <h4 class="mt-2">배터리 상태 점검 일지</h4>
        <hr/>
        <b-table thead-class="hidden_header" responsive show-empty class="position-relative fix_table text-nowrap" primary-key="id" empty-text="존재하지 않습니다"
        :items="find.list"
        :fields="find.fields">
            <template v-slot:cell(detail)="{value,item,field,index}">
                    <template v-if="value">
                        <b-button
                            variant="outline-primary"
                            class="mb-1"
                            @click="findModal(item)">
                            <span class="text-nowrap">상세보기</span>
                        </b-button>
                    </template>
            </template>
        </b-table>
        <!-- 페이징 -->
        <Page />
        <!-- 상세정보 모달 -->
        <DetailModal />
        <!-- 차드 모달 -->
        <ChartModal />
    </div>
</template>
<script>
import Page from './page/Page';
import DetailModal from './modal/DetailModal'
import ChartModal from './modal/ChartModal'

import { createHelpers } from 'vuex-map-fields';
const { mapFields } = createHelpers({
    getterType: 'main/getField', 
    mutationType: 'main/updateField', 
});

export default {
    name : 'BatteryView', 
    components : {
        Page,
        DetailModal,
        ChartModal
    },
    methods : {
        findModal(item){
            let me = this;
            me.$store.dispatch('main/findBatteryInfoDetail',{
                me : me,
                modalId : "modal-center",
                ymd : item.createDt.split("-").join(""),
                returnHash : item.returnHash?.split(",")[0],
                blockNumber : item.blockNumber?.split(",")[0],
                blockDt : item.blockDt?.split(",")[0]
            })
        },
    },
    computed : {
        ...mapFields([
            'sub.battery.find'
        ]),
    },
}
</script>
<style lang="">
    
</style>