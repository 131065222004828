<template lang="">
     <div>
        <h4 class="mt-2">배터리 상태 정보 등록</h4>
        <hr/>
        <b-row>
            <b-col cols="3">
                <label>등록일자</label>
                <b-input-group class="input-group-merge">
                    <flat-pickr 
                        placeholder="일자 선택"
                        v-model="register.ymd"
                        class="form-control"
                        :config="{dateFormat: 'Y-m-d', locale: locale, wrap: true,  maxDate: new Date()}"
                    />
                    <b-input-group-append is-text data-toggle style="cursor: pointer">
                        <feather-icon icon="CalendarIcon" size="20"  />
                    </b-input-group-append>
                </b-input-group>
            </b-col>
            <b-col cols="3">
                <label for="example-datepicker">파일첨부</label>
                <b-form-file placeholder="파일을 첨부하세요" v-model="register.fileInfo" />
            </b-col>
        </b-row>
        <hr/>
        <div class="d-flex">
            <b-button
                variant="outline-primary"
                @click="insertBtn">
                <span class="text-nowrap">등 록</span>
            </b-button>
        </div>
    </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component';
import { Korean } from 'flatpickr/dist/l10n/ko.js';

import { createHelpers } from 'vuex-map-fields';
const { mapFields } = createHelpers({
    getterType: 'main/getField', 
    mutationType: 'main/updateField', 
});

export default {
    name : 'BatteryRegister',
    components : {
        flatPickr,
        Korean
    },
    computed : {
        ...mapFields([
            'sub.battery.register'
        ]),
    },
    data(){
        return {
            locale : Korean
        }
    },
    methods : {
        insertBtn(){
            let me = this;
            let msg = "";
            if(me.register.ymd == undefined || me.register.ymd == null || me.register.ymd == "") {
                msg = "등록일자를 선택하세요"
            }
            else if(me.register.fileInfo == undefined || me.register.fileInfo == null || me.register.fileInfo == ""){
                msg = "파일을 첨부하세요";
            }
            if(msg != "") {
                me.$swal({
                    title: '알림',
                    text: msg,
                    icon: 'warning',
                    confirmButtonText: '확인',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
                return;
            }
            me.$store.dispatch("main/saveFile",{
                me : me
            });
        }
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.flatpickr-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6e6b7b !important;
  font-size : 0.857rem;
  font-weight : 700;
}

</style>