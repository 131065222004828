var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h4',{staticClass:"mt-2"},[_vm._v("배터리 수리 정비 이력 등록")]),_c('b-button',{staticClass:"mr-5",attrs:{"variant":"outline-primary"},on:{"click":_vm.addItem}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("추가")])])],1),_c('hr'),_c('b-row',{staticClass:"mt-1 mb-1"},[_c('b-col',[_c('b-form-group',{attrs:{"label-for":"shopName","label":"정비소명"}},[_c('b-form-input',{attrs:{"placeholder":"정비소명 입력","autofocus":""},model:{value:(_vm.register.shopName),callback:function ($$v) {_vm.$set(_vm.register, "shopName", $$v)},expression:"register.shopName"}})],1)],1)],1),_c('b-table',{staticClass:"position-relative fix_table text-nowrap",attrs:{"responsive":"","show-empty":"","primary-key":"id","empty-text":"등록할 내용을 추가하세요","items":_vm.register.list,"fields":_vm.register.fields},scopedSlots:_vm._u([{key:"cell(partName)",fn:function(ref){
var value = ref.value;
var item = ref.item;
var field = ref.field;
var index = ref.index;
return [_c('b-form-input',{staticClass:"mb-1",attrs:{"type":"text","placeholder":"입력"},model:{value:(item[field.key]),callback:function ($$v) {_vm.$set(item, field.key, $$v)},expression:"item[field.key]"}})]}},{key:"cell(price)",fn:function(ref){
var value = ref.value;
var item = ref.item;
var field = ref.field;
var index = ref.index;
return [_c('b-form-input',{staticClass:"text-right mb-1",attrs:{"type":"text","placeholder":"입력"},model:{value:(item[field.key]),callback:function ($$v) {_vm.$set(item, field.key, $$v)},expression:"item[field.key]"}})]}},{key:"cell(remove)",fn:function(ref){
var value = ref.value;
var item = ref.item;
var field = ref.field;
var index = ref.index;
return [_c('feather-icon',{staticClass:"cursor-pointer mb-1",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){return _vm.removeItem(index)}}})]}}])}),_c('hr'),_c('div',{staticClass:"d-flex"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.insertBtn}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("등 록")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }