<template lang="">
    <b-row>
        <b-col class="d-flex align-items-center justify-content-center">
            <b-form-group class="d-inline-block mr-1" label-cols="4" label="차량번호">
                <b-form-input
                    placeholder="차량번호"
                    v-model="vhcleNumber"
                />
            </b-form-group>
            <b-form-group class="d-inline-block mr-1" label-cols="4" label="소유주명">
                <b-form-input
                    placeholder="소유주명"
                    v-model="owerName"
                />
            </b-form-group>
            <b-button
                variant="outline-primary"
                class="mb-1"
                @click="search">
                <span class="text-nowrap">검색</span>
            </b-button>
        </b-col>
    </b-row>
</template>
<script>
import { createHelpers } from 'vuex-map-fields';
const { mapFields } = createHelpers({
    getterType: 'main/getField', 
    mutationType: 'main/updateField', 
});

export default {
    name : 'Search',
    computed : {
        ...mapFields([
            'search.vhcleNumber',
            'search.owerName',
            'loading'
        ]),
    },
    methods : {
        search(){
            let vhcleNumber = this.vhcleNumber.replace(/^\s+|\s+$/g,"");
            let owerName = this.owerName.replace(/^\s+|\s+$/g,"");

            let msg = "";
            if(vhcleNumber.length == 0) {
                msg = "차량번호를";
            }
            else if(owerName.length == 0) {
                msg = "소유주명을";
            }

            if(msg !== ""){
                this.$swal({
                    title: '알림',
                    text: msg + " 입력하세요",
                    icon: 'warning',
                    //showCancelButton: true,
                    confirmButtonText: '확인',
                    //cancelButtonText : '취소',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        //cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                })
                return;
            }
            this.$store.commit('main/resetSubInfo');
            this.$store.dispatch('main/findCarBatteryInfo',{
                me : this
            });
        }
    }
}
</script>
<style lang="">
    
</style>