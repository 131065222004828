<template lang="">
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <h4 class="mt-2">배터리 수리 정비 이력 등록</h4>
            <b-button
                variant="outline-primary"
                class="mr-5"
                @click="addItem">
                <span class="text-nowrap">추가</span>
            </b-button>
        </div>
        <hr/>
        <b-row class="mt-1 mb-1">
            <b-col>
                <b-form-group
                label-for="shopName"
                label="정비소명">
                    <b-form-input
                    v-model="register.shopName"
                    placeholder="정비소명 입력"
                    autofocus
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-table responsive show-empty class="position-relative fix_table text-nowrap" primary-key="id" empty-text="등록할 내용을 추가하세요"
        :items="register.list"
        :fields="register.fields">
            <template v-slot:cell(partName)="{value,item,field,index}">
                <b-form-input type="text" placeholder="입력" class="mb-1" v-model="item[field.key]" />
            </template>
            <template v-slot:cell(price)="{value,item,field,index}">
                <b-form-input type="text" placeholder="입력" class="text-right mb-1" v-model="item[field.key]" />
            </template>
            <template v-slot:cell(remove)="{value,item,field,index}">
                    <feather-icon
                        icon="XIcon"
                        class="cursor-pointer mb-1"
                        size="16"
                        @click="removeItem(index)"
                    />
            </template>
        </b-table>
        <hr/>
        <div class="d-flex">
            <b-button
                variant="outline-primary"
                @click="insertBtn">
                <span class="text-nowrap">등 록</span>
            </b-button>
        </div>
    </div>
</template>
<script>
import { createHelpers } from 'vuex-map-fields';
const { mapFields } = createHelpers({
    getterType: 'main/getField', 
    mutationType: 'main/updateField', 
});

export default {
    name : 'RepairRegister',
    methods : {
        // 로우 추가
        addItem(){
            this.$store.commit('main/addRepairItem');
        },
        // 로우 삭제
        removeItem(index) {
            this.$store.commit('main/removeRepairItem',{
                index : index
            });
        },
        insertBtn(){
            let me = this;
            // 1. 정비소명 필수체크
            let shopName = me.register.shopName.replace(/^\s+|\s+$/g,"");
            let msg = "";
            if(shopName.length == 0) {
                msg = "정비소명을 입력하세요";
            }
            if(msg == "") {
                // 2. 부품명 + 가격 반복문으로 체크해서 막기
                let list = me.register.list;
                if(list.length == 0) {
                    msg = "부품정보를 1개이상 입력하세요";
                }
                else {
                    for(let i = 0; i<list.length; i++) {
                        if(list[i]['partName'].length == 0) {
                            msg = "부품명은 필수입니다";
                            break;
                        }
                        else if(list[i]['price'].length == 0) {
                            msg = "가격은 필수입니다";
                            break;
                        }
                    }
                }
            }

            if(msg != "") {
                me.$swal({
                    title: '알림',
                    text: msg,
                    icon: 'warning',
                    //showCancelButton: true,
                    confirmButtonText: '확인',
                    //cancelButtonText : '취소',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        //cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                })
                return;
            }

            // 3. 통과
            me.$store.dispatch("main/saveRepair",{
                me : me
            });
        }
    },
    computed : {
        ...mapFields([
            'sub.repair.register'
        ]),
    },
}
</script>
<style lang="">
    
</style>