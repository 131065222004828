<template lang="">
    <b-navbar
        :toggleable="false"
        :variant="navbarBackgroundColor"
        class="header-navbar navbar navbar-shadow align-items-center"
        >
            <slot
                name="navbar"
                :toggleVerticalMenuActive="toggleVerticalMenuActive"
                :navbarBackgroundColor="navbarBackgroundColor"
                :navbarTypeClass="['header-navbar navbar navbar-shadow align-items-center']"
            >
                <div class="navbar-container d-flex content align-items-center">
                    <b-navbar-nav class="nav align-items-center ml-auto">
                        <b-nav-item-dropdown
                            right
                            toggle-class="d-flex align-items-center dropdown-user-link"
                            class="dropdown-user">
                            <template #button-content>
                                <div class="d-sm-flex d-none user-nav">
                                    <p class="user-name font-weight-bolder mb-0">
                                    {{ userData.name }}({{ userData.nickName }})
                                    </p>
                                    <span class="user-status">{{ userData.role == "admin" ? "관리자" :  userData.role == "typist" ? "타이피스트" : "" }}</span>
                                </div>
                                <b-avatar
                                    size="40"
                                    variant="light-primary"
                                    badge
                                    :src="userData.avatar"
                                    class="badge-minimal"
                                    badge-variant="success"
                                />
                            </template>
                            <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
                                <feather-icon
                                    size="16"
                                    icon="LogOutIcon"
                                    class="mr-50"
                                />
                                <span>로그아웃</span>
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                    </b-navbar-nav>
                </div>
            </slot>
        </b-navbar>
</template>
<script>
import { onUnmounted } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import useVerticalLayout from '@/views/layout/useVerticalLayout'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default {
    name : 'TopBar', 
    data(){
        return {
            userData: JSON.parse(localStorage.getItem('userData'))
        }
    },
    methods : {
        logout(){
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
            localStorage.removeItem('userData')
            this.$ability.update(initialAbility)
            this.$router.push({ name: 'login' })
        },
    },
    setup() {
        const {
            routerTransition, navbarBackgroundColor, navbarType, footerType, isNavMenuHidden,
        } = useAppConfig();

        const {
            isVerticalMenuActive,
            toggleVerticalMenuActive,
            isVerticalMenuCollapsed,
            layoutClasses,
            overlayClasses,
            resizeHandler,
            footerTypeClass,
        } = useVerticalLayout(navbarType, footerType);

        resizeHandler()
            window.addEventListener('resize', resizeHandler)
        onUnmounted(() => {
            window.removeEventListener('resize', resizeHandler)
        })

        return {
            isVerticalMenuActive,
            toggleVerticalMenuActive,
            isVerticalMenuCollapsed,
            overlayClasses,
            layoutClasses,
            footerTypeClass,
            // App Config
            routerTransition,
            navbarBackgroundColor,
            isNavMenuHidden,
        }
    },
}
</script>
<style lang="">
    
</style>