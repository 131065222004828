var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"lg","ok-only":true,"id":"modal-center","scrollable":"","title":"수리정비 상세내역","ok-title":"닫기"}},[_c('b-table',{staticClass:"position-relative fix_table text-nowrap",attrs:{"responsive":"","show-empty":"","primary-key":"id","empty-text":"존재하지 않습니다","thead-class":"hidden_header","items":_vm.detail.list,"fields":_vm.detail.fields},scopedSlots:_vm._u([{key:"cell(price)",fn:function(ref){
var value = ref.value;
var item = ref.item;
var field = ref.field;
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.commaRender(value))+" ")]}}])}),_c('hr'),_c('b-table',{staticClass:"position-relative fix_table text-nowrap",attrs:{"responsive":"","show-empty":"","primary-key":"id","empty-text":"존재하지 않습니다","thead-class":"hidden_header","items":_vm.detail.sum,"fields":_vm.detail.fields},scopedSlots:_vm._u([{key:"cell(price)",fn:function(ref){
var value = ref.value;
var item = ref.item;
var field = ref.field;
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.commaRender(value))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }