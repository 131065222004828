<template lang="">
    <b-modal
        size="lg"
        :ok-only=true
        id="modal-center"
        scrollable
        title="배터리상태 정보"
        ok-title="닫기">
        <b-overlay
                    id="overlay-background2"
                    :show="chartLoading"
                    variant="white"
                    opacity="0.8"
                    rounded="sm"
                >
            <b-col class="pl-0" v-if="returnHash != ''">
                hash : {{returnHash}}
            </b-col>
            <b-col class="pl-0" v-if="blockNumber != ''">
                block_number : {{blockNumber}}
            </b-col>
            <b-col class="pl-0" v-if="blockDt != ''">
                date : {{blockDt}}
            </b-col>
            <b-table responsive show-empty class="position-relative fix_table text-nowrap mt-1" primary-key="id" empty-text="존재하지 않습니다"
                    :items="detail.list"
                    :fields="detail.fields">
                <template v-slot:cell(detail)="{value,item,field,index}">
                        <template v-if="value">
                            <feather-icon
                                icon="BarChartIcon"
                                class="cursor-pointer mb-1"
                                size="18"
                                @click="findModal(item)"
                            />
                        </template>
                </template>
            </b-table>
        </b-overlay>
    </b-modal>
</template>
<script>
import { createHelpers } from 'vuex-map-fields';
const { mapFields } = createHelpers({
    getterType: 'main/getField', 
    mutationType: 'main/updateField', 
});

export default {
    name : 'DetailModal',
    computed : {
        ...mapFields([
            'sub.battery.find.modal.detail',
            'sub.battery.find.modal.returnHash',
            'sub.battery.find.modal.blockNumber',
            'sub.battery.find.modal.blockDt',
            'chartLoading'
        ]),
    },
    methods : {
        findModal(item){
            let me = this;
            me.$store.dispatch('main/findBatteryInfoDetailHour',{
                me : me,
                modalId : "modal-center2",
                pid : item.pid,
                ymd : item.ymd,
                carId : item.carId
            })
        }
    }
}
</script>
<style lang="">
    
</style>